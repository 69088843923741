import { render, staticRenderFns } from "./PlaylistPlayer.vue?vue&type=template&id=84e8e5bc&scoped=true&"
import script from "./PlaylistPlayer.vue?vue&type=script&lang=js&"
export * from "./PlaylistPlayer.vue?vue&type=script&lang=js&"
import style0 from "./PlaylistPlayer.vue?vue&type=style&index=0&id=84e8e5bc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84e8e5bc",
  null
  
)

export default component.exports